import styled, { css } from 'styled-components'
import { typography, spacing, color, hideSMDown, mbSize, pcSize } from '@renderbus/common/theme'
import { H1, H3, H2, Flex, Anchor, Wrapper } from '@renderbus/common/components'
import Media from '@renderbus/common/theme/media'
import GatsbyImage from 'gatsby-image'

export const ModalCSS = css`
  .modal-header {
    padding: 0;
  }
  .modal-close-icon {
    z-index: 10000;
    .close-wrap {
      width: 24px;
      height: 24px;
    }
    top: 40px;
    right: 40px;
  }
  .modal-content {
    border-radius: 10px;
  }
  h6 {
    display: none !important;
  }
`

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: ${pcSize(600)};
  padding-top: ${pcSize(220)};
  color: white;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(720)};
    padding-top: ${mbSize(260)};
  }
`

export const BannerTitle = styled(H1)`
  line-height: ${pcSize(72)};
  font-size: ${pcSize(60)};
  font-weight: 700;
  ${Media.lessThan(Media.small)} {
    line-height: ${mbSize(108)};
    font-size: ${mbSize(72)};
  }
`

export const BannerSubTitle = styled.div`
  margin: ${pcSize(20)} 0 ${pcSize(40)} 0;
  font-weight: 400;
  font-size: ${pcSize(24)};
  line-height: ${pcSize(30)};
  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(20)} 0 ${mbSize(60)} 0;
    font-size: ${mbSize(30)};
    line-height: ${mbSize(48)};
    letter-spacing: ${mbSize(12)};
  }
`

export const BannerButton = styled.div`
  cursor: pointer;
  width: ${pcSize(170)};
  height: ${pcSize(44)};
  border-radius: ${pcSize(80)};
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-weight: 400;
  font-size: ${pcSize(18)};
  color: #ffffff;
  line-height: ${pcSize(24)};
  img {
    width: ${pcSize(24)};
    height: ${pcSize(24)};
    margin-right: ${pcSize(13)};
  }
  .hover {
    display: none;
  }
  &:hover {
    border-color: #13cb75;
    color: #13cb75;
    .normal {
      display: none;
    }
    .hover {
      display: block;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(248)};
    height: ${mbSize(72)};
    border-radius: ${mbSize(120)};
    font-size: ${mbSize(24)};
    line-height: ${mbSize(36)};
    border: 1px solid #ffffff;
    img {
      width: ${mbSize(36)};
      height: ${mbSize(36)};
      margin-right: ${mbSize(20)};
    }
  }
`

export const PlayImg = styled.img`
  ${hideSMDown};
`

export const WarpperContainer = styled.div`
  padding: 100px 0 100px;
  background: ${p => p.lighter && color.panel};

  ${({ type }) => {
    if (type === 'summary')
      return css`
        padding: 85px 0 100px;
      `
  }}

  ${Media.lessThan(Media.small)} {
    padding: 50px 0;
  }
`
export const BrandWarpperContainer = styled(WarpperContainer)`
  padding: 80px 0 100px;
`
export const WrapperTitle = styled(H2)`
  font-size: 40px;
  text-align: center;
  line-height: 1;

  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
    margin-bottom: ${mbSize(60)};
  }
`

export const FirstWrapperTitle = styled(H2)`
  font-size: 40px;
  text-align: center;
  line-height: 1;
  margin-right: 80px;
  width: 200px;
  text-align: left;
  line-height: 56px;
  ${Media.lessThan(Media.small)} {
    width: max-content;
    font-size: ${mbSize(48)};
    line-height: ${mbSize(72)};
    margin: 0 0 ${mbSize(60)} 0;
  }
`
export const FirstWrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 100px 0 100px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    padding: 0 0 ${mbSize(80)} 0;
  }
`
export const IntroContent = styled.div`
  width: 920px;
  p {
    margin: 0 0 ${spacing.large} 0;
    line-height: 30px;
    text-align: left;
    font-size: 16px;
    color: #cccccc;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(670)};
    line-height: ${mbSize(40)};
    font-size: ${mbSize(24)};
    p {
      font-size: ${mbSize(24)};
      line-height: ${mbSize(40)};
    }
  }
`

export const FamilyContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  .family-button {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
  }

  ${Media.lessThan(Media.small)} {
    background: #222222;
    .family-button {
      bottom: ${mbSize(20)};
      width: ${mbSize(210)};
      height: ${mbSize(60)};
      line-height: ${mbSize(60)};
    }
  }
`

export const FamilyInfo = styled.div`
  position: absolute;
  top: 60.75%;
  left: 50%;
  transform: translate(-50%);
  width: 1200px;
  height: 260px;
  margin: 0 auto;
  padding: 40px 100px;
  color: white;
  background: #0003;
  font-size: ${typography.text};
  text-align: left;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0.6) 0%, rgba(34, 34, 34, 0) 100%);
  border-radius: 20px 20px 20px 20px;
  backdrop-filter: blur(4px);

  p {
    margin: 0 0 ${spacing.base} 0;

    &:last-child {
      margin: 0;
    }
  }

  ${Media.lessThan(Media.small)} {
    position: relative;
    top: initial;
    left: initial;
    transform: translate(0);
    padding: 0;
    width: ${mbSize(710)};
    height: ${mbSize(492)};
    font-size: ${mbSize(24)};
    line-height: ${mbSize(36)};
    background: linear-gradient(180deg, rgba(34, 34, 34, 0.6) 0%, rgba(34, 34, 34, 0) 100%);
    border-radius: 10px 10px 10px 10px;
    padding: ${mbSize(60)} ${mbSize(30)};
    margin-top: -50px;
  }
`

export const PartnerWrapper = styled.div`
  background: #333333;
  padding: 100px 0;

  ${Wrapper} {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(80)} 0 ${mbSize(86)} 0;
    height: max-content;
  }
`

export const PartnerContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;

  ${Media.lessThan(Media.small)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 15px;
    margin: 0;
  }
`

export const ContactContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 52px 0 80px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
  }
`
export const ContactContent = styled.div`
  margin-left: 70px;

  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-left: 0;
  }
`
export const ContactTitle = styled(H3)`
  font-weight: 500;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: ${spacing.large} 0;
  }
`
export const BrandWrapper = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin: 0 100px;
  padding: 50px 80px 0 100px;
  height: 260px;
  background: rgba(51, 51, 51, 0.4);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #666666;
  align-items: flex-start;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    margin: 0;
    width: ${mbSize(710)};
    height: ${mbSize(380)};
    padding: ${mbSize(60)} ${mbSize(130)};
  }
`
export const BrandCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 160px;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
  img {
    width: 240px;
    height: 66px;
  }
  ${p =>
    p.square &&
    css`
      width: 160px;
      height: 160px;
      img {
        width: 88px;
        height: 110px;
      }
    `}
`
export const BrandCardWrapper = styled.div`
  display: flex;
  ${BrandCard}:first-child {
    margin-right: 40px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const Brand = styled(Flex)`
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.base};
    img {
      padding-bottom: ${spacing.base};
    }
  }
`
export const BrandContent = styled.div`
  color: white;
  a {
    display: inline-flex;
    align-items: center;
  }
  p {
    margin: 10px 0 40px 0;
    font-size: 20px;
    line-height: 24px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: ${mbSize(20)} 0 ${mbSize(40)} 0;
      font-size: ${mbSize(30)};
      line-height: ${mbSize(48)};
    }
    img {
      width: ${mbSize(30)};
      height: ${mbSize(30)};
      margin-right: ${mbSize(12)};
      padding: 0;
    }
  }
`
export const BrandTitle = styled(H2)`
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    width: max-content;
    font-size: ${mbSize(48)};
    line-height: ${mbSize(72)};
  }
`

export const TeamImage = styled(GatsbyImage)`
  ${Media.lessThan(Media.small)} {
    width: 100vw;
    height: auto;
    margin: 0 auto;

    & > div {
      /* （100 / 1.8449） */
      padding-bottom: 59% !important;
    }
  }
`

export const PartnerImageWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 104px;
  background: #fff;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;

  & + & {
    margin: 0 0 0 16px;
  }

  ${Media.lessThan(Media.small)} {
    width: ${mbSize(330)};
    height: ${mbSize(150)};

    & + & {
      margin: 0;
    }

    & > img {
      max-width: 90%;
    }
  }
`

export const More = styled(Anchor)`
  position: relative;
  display: inline-block;
  margin: 60px 0 0;
  color: ${color.primary};

  &::after {
    position: absolute;
    content: '>';
    display: block;
    right: -10px;
    top: 0;
  }

  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(64)} 0 0;
  }
`
